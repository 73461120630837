<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Ajuste del Software</h4>
            <div class="small text-muted">Administre las características visuales del software</div>
          </b-col>
        </b-row>
      </b-card>

      <div>
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="Título" active>
              <b-card-text>
                <b-row>
                  <b-col>
                    <b-form-group label="Proyecto" class="settings-proyecto-label-custom" description="Ingrese el nombre de la empresa que va a utilzar el software">
                      <b-form-input v-model="form.proyectName" type="text" required placeholder="Nombre de Proyecto"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>
            <b-tab title="Imágenes">
              <b-card-text>
                <b-row>
                  <b-col>
                    <b-form-group label="Logo" class="settings-imagen-label-custom" description="Medidas en relación a 150x50">
                      <Imagen :images="form.logo" @load-image="form.logo = $event" typeImage="Logo"/>                                                                    
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Favicon" class="settings-imagen-label-custom" description="Medidas en relación a 50x50">
                      <Imagen :images="form.favicon" @load-image="form.favicon = $event" typeImage="Favicon"/>                      
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Imagen Login" class="settings-imagen-label-custom" description="Medidas en relación a 1152x754">
                      <Imagen :images="form.imageLogin" @load-image="form.imageLogin = $event" typeImage="ImageLogin"/>                      
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>
            <b-tab title="Colores">
              <b-card-text>
                <b-row>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-group label="Color Principal">
                          <b-form-input v-model="form.colorPrimary" type="color"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="Color Menu Sidebar">
                          <b-form-input v-model="form.colorTitleMenu" type="color"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="Color Hover Sidebar">
                          <b-form-input v-model="form.colorSelectMenu" type="color"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>
            <b-tab title="PWA">
              <b-card-text>
                <b-row>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-alert show variant="secondary">
                          Listamos algunas webs para generar los logos en las medidas solicitadas:<br>
                          <ul>
                            <li><a href="https://www.simicart.com/manifest-generator.html" target="_blank">https://www.simicart.com/manifest-generator.html</a></li>
                            <li><a href="https://www.pwabuilder.com/imageGenerator" target="_blank">https://www.pwabuilder.com/imageGenerator</a></li>
                            <li><a href="https://cthedot.de/icongen" target="_blank">https://cthedot.de/icongen</a></li>
                            <li><a href="https://realfavicongenerator.net" target="_blank">https://realfavicongenerator.net</a></li>
                          </ul>
                        </b-alert>                        
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-group label="Icono 192x192">
                          <Imagen :images="form.pwaIcon192" @load-image="form.pwaIcon192 = $event" typeImage="ImagenIcon192"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Icono 256x256">
                          <Imagen :images="form.pwaIcon256" @load-image="form.pwaIcon256 = $event" typeImage="ImagenIcon256"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Icono 384x384">
                          <Imagen :images="form.pwaIcon384" @load-image="form.pwaIcon384 = $event" typeImage="ImagenIcon384"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Icono 512x512">
                          <Imagen :images="form.pwaIcon512" @load-image="form.pwaIcon512 = $event" typeImage="ImagenIcon512"/>
                        </b-form-group>
                      </b-col>      
                      <b-col md="6">
                        <b-form-group label="Manifiesto APP">
                          <prism-editor v-model="form.pwaManifestApp" language="json" class="settings-pwa-manifest"></prism-editor>            
                        </b-form-group>
                      </b-col> 
                      <b-col md="6">
                        <b-form-group label="Manifiesto Sitio Web">
                          <prism-editor v-model="form.pwaManifestWeb" language="json" class="settings-pwa-manifest"></prism-editor>            
                        </b-form-group>
                      </b-col>                                                             
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>            
          </b-tabs>
        </b-card>
      </div>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button variant="dark" class="pull-right" @click="guardar()">Guardar</b-button>
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import serviceAPI from './services'
  import Imagen from '@/components/inc/image/image' 
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";
  import 'prismjs/components/prism-json';

  export default {
    components: {
      Imagen,
      PrismEditor,
    },
    data () {
      return {
        form: {
          logo: [],
          favicon: [],
          imageLogin: [],
          proyectName: '',
          colorPrimary: '',
          colorTitleMenu: '',
          colorSelectMenu: '',
          pwaIcon192: [],
          pwaIcon256: [],
          pwaIcon384: [],
          pwaIcon512: [],
          pwaManifestApp: '',
          pwaManifestWeb: '',
        }
      }
    },
    mounted() {
      this.cargar()
    },
    computed: {
      renderManifestApp() {
        return {
          name: "Genérico APP",
          short_name: "Genérico APP",
          start_url: "/",
          display: "standalone",
          theme_color: "#000000",
          background_color: "#fff",
          gcm_sender_id: "103953800507",
          icons: [
            {
              src: "static/icons/icon-192x192.png",
              sizes: "192x192",
              type: "image/png"
            },
            {
              src: "static/icons/icon-256x256.png",
              sizes: "256x256",
              type: "image/png"
            },
            {
              src: "static/icons/icon-384x384.png",
              sizes: "384x384",
              type: "image/png"
            },
            {
              src: "static/icons/icon-512x512.png",
              sizes: "512x512",
              type: "image/png"
            }            
          ]          
        }      
      },
      renderManifestWeb() {
        return {
          name: "Genérico WEB",
          short_name: "Genérico WEB",
          start_url: "/",
          display: "standalone",
          theme_color: "#000000",
          background_color: "#fff",
          gcm_sender_id: "103953800507",
          icons: [
            {
              src: "static/icons/icon-192x192.png",
              sizes: "192x192",
              type: "image/png"
            },
            {
              src: "static/icons/icon-256x256.png",
              sizes: "256x256",
              type: "image/png"
            },
            {
              src: "static/icons/icon-384x384.png",
              sizes: "384x384",
              type: "image/png"
            },
            {
              src: "static/icons/icon-512x512.png",
              sizes: "512x512",
              type: "image/png"
            }            
          ]          
        }      
      }      
    },
    methods: {
      cargar () {
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data

          if(data.settings[0].value) {
            this.form.logo = [{ path: data.settings[0].value }]
          }

          if(data.settings[1].value) {
            this.form.favicon = [{ path: data.settings[1].value }]
          }

          if(data.settings[2].value) {
            this.form.imageLogin = [{ path: data.settings[2].value }]
          }

          this.form.proyectName = data.settings[3].value
          this.form.colorPrimary = data.settings[4].value
          this.form.colorTitleMenu = data.settings[5].value
          this.form.colorSelectMenu = data.settings[6].value

          if(data.settings[7].value) {
            this.form.pwaIcon192 = [{ path: data.settings[7].value }]
          }
          
          if(data.settings[8].value) {
            this.form.pwaIcon256 = [{ path: data.settings[8].value }]
          }
          
          if(data.settings[9].value) {
            this.form.pwaIcon384 = [{ path: data.settings[9].value }]
          }
          
          if(data.settings[10].value) {
            this.form.pwaIcon512 = [{ path: data.settings[10].value }]
          }    
          
          if(data.settings[11].value) {
            this.form.pwaManifestApp = data.settings[11].value
          } else {
            this.form.pwaManifestApp = JSON.stringify(this.renderManifestApp, null, 4)
          }
                      
          if(data.settings[12].value) {
            this.form.pwaManifestWeb = data.settings[12].value
          } else {
            this.form.pwaManifestWeb = JSON.stringify(this.renderManifestWeb, null, 4)
          }           
        })
      },
      guardar () {
        let loader = this.$loading.show()

        var logo = new FormData();
        this.form.logo.forEach((value, key) => {
          logo.append(key, value.path);
        })

        var favicon = new FormData();
        this.form.favicon.forEach((value, key) => {
          favicon.append(key, value.path);
        })

        var imageLogin = new FormData();
        this.form.imageLogin.forEach((value, key) => {
          imageLogin.append(key, value.path);
        })

        var pwaIcon192 = new FormData();
        this.form.pwaIcon192.forEach((value, key) => {
          pwaIcon192.append(key, value.path);
        })

        var pwaIcon256 = new FormData();
        this.form.pwaIcon256.forEach((value, key) => {
          pwaIcon256.append(key, value.path);
        })
        
        var pwaIcon384 = new FormData();
        this.form.pwaIcon384.forEach((value, key) => {
          pwaIcon384.append(key, value.path);
        })
        
        var pwaIcon512 = new FormData();
        this.form.pwaIcon512.forEach((value, key) => {
          pwaIcon512.append(key, value.path);
        })        

        var result = serviceAPI.guardar(JSON.stringify(this.form), logo, favicon, imageLogin, pwaIcon192, pwaIcon256, pwaIcon384, pwaIcon512)

        result.then((response) => {
          var data = response.data
          if (data.status) {
            Session.setSessionSettings(data)
            this.$awn.success("Configuración Guardada")
            this.$router.push({ name: 'Init' })
          } else {
            this.$awn.info("Faltan cargar algunos campos")
          }

          loader.hide()
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
          loader.hide()
        });
      },
    },
  }
</script>
<style>
  .settings-proyecto-label-custom legend {
    text-align: left !important;
  }
  .settings-imagen-label-custom legend {
    text-align: left !important;
  }
  legend {
    font-weight: 600;
  }
  .settings-pwa-manifest {
    height: 600px;
  }
  .settings-pwa-manifest pre {
    height: 600px;
  }  
</style>
